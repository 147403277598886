.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-zoom infinite 10s linear;
  }
}

#barncancer {    
  position: fixed;
  bottom: 100px;
  right: 20px;
}

@media only screen and (min-width:320px) and (max-device-width : 800px) {
  #barncancer {
      width: 25%;
  }
}@media only screen and (min-width: 801px) {
  #barncancer {
      width: 10%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-zoom {
    from {
      transform: scale(1,1);
    }
    /* 25%{
      transform: scale(1.05,0.95);
    } */
    50% {
      transform: scale(1.1, 1.1);
    }
    /* 75% {
      transform: scale(0.95, 1.05);
    } */
    to {
      transform: scale(1, 1);
    }
  }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
